<template>
  <section class="fontStandard">
    <!-- User Interface controls -->
    <b-row class="pt-5">
      <b-col cols="12" md="4" lg="4" class="text-left">
        <h3>
          <b-badge variant="light"
            >{{ totalRows }} entrée{{ totalRows > 1 ? "s" : "" }}
          </b-badge>
        </h3>
      </b-col>
      <b-col cols="0" md="4" lg="4"></b-col>
      <b-col cols="12" md="4" lg="4">
        <b-input-group>
          <b-button @click="$refs.table.refresh()" variant="primary">
            <i class="fas fa-sync"></i>
          </b-button>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Recherche"
          ></b-form-input>
          <b-input-group-append></b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- 
      Main table element :busy.sync="isBusy"
      thead-class="bg-info"
      tfoot-class="bg-info"
      table-class="bg-light"
    -->
    <b-table
      show-empty
      small
      responsive
      stacked="lg"
      ref="table"
      :items="myProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      striped
      hover
      foot-clone
      bordered
      borderless
      head-variant="dark"
      table-variant="light"
    >
      <template v-slot:table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(id)="data">
        <!-- info(data.item, data.index, $event.target) -->

        <b-link @click="info(data.item, data.index, $event.target)">
          <b>#{{ parseInt(data.value) }}</b>
        </b-link>
      </template>

      <template v-slot:cell(user)="data">
        <b-link @click="info(data.item, data.index, $event.target)">
          <b>{{ data.value }}</b>
        </b-link>
      </template>

      <template v-slot:cell(fullName)="data">
        <b>{{ data.value }}</b>
      </template>

      <template v-slot:cell(enable)="data">
        <b
          :class="[data.item.enable === '1' ? 'text-primary' : 'text-danger']"
          >{{ data.value }}</b
        >
      </template>

      <template v-slot:cell(loggedIn)="data">
        <b
          :class="[data.item.loggedIn === '1' ? 'text-primary' : 'text-danger']"
          >{{ data.value }}</b
        >
      </template>

      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click="info(row.item, row.index, $event.target)"
          class="mr-1"
          variant="primary"
          pill
        >
          <i class="fas fa-user-cog"></i>&nbsp;
          <strong>Modifier</strong>
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col cols="8">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="left"
          first-number
          last-number
        />
      </b-col>
      <b-col cols="4" class="text-right">
        <b-form-select
          style="width: 5em"
          v-model="perPage"
          id="perPageSelect"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
    </b-row>

    <b-modal
      :id="infoModal.id"
      title="Modifier Profil"
      ok-only
      @hide="resetInfoModal"
      no-stacking
      centered
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      ok-variant="light"
      header-close-variant="light"
      header-text-variant="light"
      header-border-variant="dark"
      hide-footer
      scrollable
      size="lg"
    >
      <AccountView :items="infoModal.item" />
    </b-modal>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    AccountView: () => import("./AccountView"),
  },
  computed: {
    ...mapState(["account", "events"]),
    ...mapGetters(["admin/allow_this_tab", "admin/allow_this_action"]),
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: "text-left",
        },
        {
          key: "user",
          label: "Compte",
          sortable: true,
          class: "text-left",
        },
        {
          key: "fullName",
          label: "Nom complet",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "enable",
          label: "Actif",
          formatter: (value, key, item) => {
            return value === "1" ? "Oui" : "Non";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "loggedIn",
          label: "Connecté",
          formatter: (value, key, item) => {
            return value === "1" ? "Oui" : "Non";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [1, 7, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      isBusy: false,
      infoModal: {
        id: "info-modal",

        item: null,
      },
    };
  },
  mounted() {
    this.currentPage = 1;
  },
  beforeDestroy() {
    this.items = this.fields = this.pageOptions = undefined;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.item = item;

      // console.error(index);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.item = null;
      this.$refs.table.refresh();
    },
    async myProvider(ctx) {
      this.isBusy = true;
      if (ctx && ctx.filter && ctx.filter.length > 30) {
        this.totalRows = 0;
        this.isBusy = false;
        return [];
      }

      this.$store.dispatch("axios/http_reading", true);
      //    const data = await this.$root.axios("get", "admin/account/list", {
      //      currentPage: ctx.currentPage,
      //      perPage: ctx.perPage,
      //      filter: ctx.filter,
      //      sortBy: ctx.sortBy,
      //      sortDesc: ctx.sortDesc,
      //    });
      const vm = this.$root;
      await vm.handleGetRequest("api/token/get", false);
      await vm.handleGetRequest("admin/what/are/my/rights", false);
      const data = await vm.handleGetRequest("admin/account/list", true, {
        currentPage: ctx.currentPage,
        perPage: ctx.perPage,
        filter: ctx.filter,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
      });

      const tbl =
        data && data["admin_account_list"] ? data["admin_account_list"] : null;

      this.currentPage =
        tbl && tbl.currentPage ? tbl.currentPage : this.currentPage;
      this.totalRows = tbl && tbl.totalRows ? tbl.totalRows : 0;

      if (tbl && tbl.items) {
        this.isBusy = false;
        return JSON.parse(tbl.items);
      } else {
        this.currentPage = 1;
        this.isBusy = false;
        return [];
      }
    },
    canDisableAction(action = "") {
      if (
        typeof action !== "undefined" &&
        typeof action.item !== "undefined" &&
        typeof action.item.rank !== "undefined" &&
        typeof action.item.id !== "undefined" &&
        this.$root.getContent()["id"]
      ) {
        const rank = action.item.rank;

        let response = false;

        if (parseInt(action.item.id) === this.$root.getContent()["id"]) {
          response = false;
        } else if (rank === "root") {
          response = this["admin/allow_this_action"]("account_disable_root")
            ? true
            : false;
        } else {
          response = this["admin/allow_this_action"]("account_disable")
            ? true
            : false;
        }

        return response;
      } else {
        return false;
      }
    },
    canChangeAction(action = "") {
      if (
        typeof action !== "undefined" &&
        typeof action.item !== "undefined" &&
        typeof action.item.rank !== "undefined" &&
        typeof action.item.id !== "undefined" &&
        this.$root.getContent()["id"]
      ) {
        const rank = action.item.rank;

        let response = false;

        if (parseInt(action.item.id) === this.$root.getContent()["id"]) {
          response = false;
        } else if (rank === "root") {
          response = this["admin/allow_this_action"]("account_change_root")
            ? true
            : false;
        } else {
          response = this["admin/allow_this_action"]("account_change")
            ? true
            : false;
        }

        return response;
      } else {
        return false;
      }
    },
    convertMysqlDatetime(datetimemysql = "1970-01-01 00:00:00") {
      const t = datetimemysql.split(/[- :]/);
      const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
      return d;
    },
  },
};
</script>

<style scoped>
.table_color_light {
  background: red;
}
</style>